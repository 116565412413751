import {
  type AnalyticsBrowser,
  type EventProperties,
} from '@segment/analytics-next';
import { useMemo } from 'react';

import { useMyOrgId } from '../components/Organization/hooks/organization';
import { useAnalytics } from './AnalyticsContext';

class OnboardingAnalytics {
  constructor(
    private readonly analytics: AnalyticsBrowser,
    readonly orgId: string | null
  ) {}

  trackOnboardingTaskSkipped(props: EventProperties) {
    this.analytics.track(
      'Onboarding Task Skipped',
      this.buildEventProps(props)
    );
  }

  trackOnboardingTaskCardClicked(props: EventProperties) {
    this.analytics.track(
      'Onboarding Task Card Clicked',
      this.buildEventProps(props)
    );
  }

  trackOnboardingNameSaved(props?: EventProperties) {
    this.analytics.track('Onboarding Name Saved', this.buildEventProps(props));
  }

  trackOnboardingCallViewed(props?: EventProperties) {
    this.analytics.track('Onboarding Call Viewed', this.buildEventProps(props));
  }

  trackOnboardingCallDismissed(props?: EventProperties) {
    this.analytics.track(
      'Onboarding Call Dismissed',
      this.buildEventProps(props)
    );
  }

  trackOnboardingCallScheduled(props?: EventProperties) {
    this.analytics.track(
      'Onboarding Call Scheduled',
      this.buildEventProps(props)
    );
  }

  trackOnboardingAddMembers(props: EventProperties) {
    this.analytics.track('Onboarding Add Members', this.buildEventProps(props));
  }

  trackOnboardingMenuSelected(props: EventProperties) {
    this.analytics.track(
      'Onboarding Menu Clicked',
      this.buildEventProps(props)
    );
  }

  trackOnboardingCompletedButtonClicked(props: EventProperties) {
    this.analytics.track(
      'Onboarding Completed Button Clicked',
      this.buildEventProps(props)
    );
  }

  trackOnboardingBillingButtonClicked(props?: EventProperties) {
    this.analytics.track(
      'Onboarding Billing Button Clicked',
      this.buildEventProps(props)
    );
  }

  trackOnboardingMissingFeaturesButtonClicked(props?: EventProperties) {
    this.analytics.track(
      'Onboarding Missing Features Button Clicked',
      this.buildEventProps(props)
    );
  }

  trackOnboardingOndGameClicked(props?: EventProperties) {
    this.analytics.track(
      'Onboarding Ond Game Clicked',
      this.buildEventProps(props)
    );
  }

  trackOnboardingProgramsSkipped(props?: EventProperties) {
    this.analytics.track(
      'Onboarding Programs Skipped',
      this.buildEventProps(props)
    );
  }

  trackOnboardingProgramsInstalled(props?: EventProperties) {
    this.analytics.track(
      'Onboarding Programs Installed',
      this.buildEventProps(props)
    );
  }

  private buildEventProps(props?: EventProperties): EventProperties {
    return {
      ...props,
      orgId: this.orgId,
    };
  }
}

export function useOnboardingAnalytics(): OnboardingAnalytics {
  const analytics = useAnalytics();
  const orgId = useMyOrgId();

  return useMemo(
    () => new OnboardingAnalytics(analytics, orgId),
    [analytics, orgId]
  );
}
